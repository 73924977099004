<template>
  <div>
    <header>
      <h1>{{ localize('banner')['news']['title'] }}</h1>
    </header>

    <div class="container">

      <div v-if="news !== null" class="detail row">
        <div class="news-image col-md-6 col-xl-6 col-12">
          <img
            :src="news.locale.image && news.locale.image.preview
                ? news.locale.image.preview
                : ''"
            alt=""
            width="100%"
          />
        </div>

        <div class="news-text col-md-6 col-xl-6 col-12">
          <h2 v-html="news.locale.title"></h2>
          <div v-html="news.locale.detail_text"></div>
        </div>
      </div>

      <h2>{{ localize('last-news') }}</h2>

      <div v-if="newsData.length > 0" class="last">

        <swiper class="swiper" :options="swiperOption">

          <swiper-slide
            class="swiper_slider"
            v-for="(news, i) in newsData"
            :key="i"
          >
            <news-card
              :id="news.id"
              :slug="news.locale.slug"
              :image="news.locale.image"
              :heading="news.locale.preview_text"
              :title="news.locale.title"
              :date="news.date"
            />
          </swiper-slide>

        </swiper>
      </div>

    </div>
  </div>
</template>

<script>
import localize from '../filters/localize.filter';
import NewsCard from '../components/NewsCard';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.min.css';

export default {
  name: 'NewsDetail',
  components: {
    NewsCard,
    Swiper,
    SwiperSlide,
  },
  computed: {
    newsId() {
      return this.$route.params.slug;
    },
  },
  asyncComputed: {
    async updateNews() {
      if (this.$route.params.slug) {
        try {
          const { data: news } = await this.$axios.get(`news/detail/${this.$route.params.slug}`, {
            headers: {
              'Content-Language': this.$i18n.locale,
            },
          });

          if (news.status === 'success') {
            this.news = news.data;
          } else {
            news.response.errors.forEach(error => this.$toastError(error));
          }

        } catch (e) {
          this.$toastError(e.message);
        }
      }
    },
  },
  data() {
    return {
      newsData: [],
      news: null,
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 32,
        freeMode: true,
        breakpoints: {
          // when window width is >= 320px
          200: {
            slidesPerView: 1.1,
            spaceBetween: 20,
            freeMode: false,
          },
          // when window width is >= 480px
          576: {
            slidesPerView: 1,
            spaceBetween: 30,
            freeMode: false,
          },
          // when window width is >= 640px
          768: {
            slidesPerView: 2,
            spaceBetween: 32,
            freeMode: false,
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 32,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 32,
          },
        },
      },
    };
  },
  methods: {
    localize,
  },
  async created() {
    try {
      const { data: news } = await this.$axios.get(`news/detail/${this.newsId}`, {
        headers: {
          'Content-Language': this.$i18n.locale,
        },
      });

      if (news.status === 'success') {
        this.news = news.data;
      } else {
        news.response.errors.forEach(error => this.$toastError(error));
      }

    } catch (e) {
      this.$toastError(e.message);
    }

    try {
      const { data: news } = await this.$axios.get('news/list', {
        headers: {
          'Content-Language': this.$i18n.locale,
        },
      });
      if (news.status === 'success') {
        this.newsData = news.data.filter(({ is_mobile }) => is_mobile === 0);
      } else {
        news.response.errors.forEach(error => this.$toastError(error));
      }

    } catch (e) {
      this.$toastError(e.message);
    }
  },
};
</script>

<style lang="scss" scoped>
  .text-center {
    text-align: center;
  }

  header {
    text-align: center;
    padding: 56px 0;
    background: $grey url("../assets/icons/Vector.svg") no-repeat calc(50% - 120px) 37px;

    h1 {
      font-weight: 900;
      font-size: 40px;
      line-height: 40px;
      color: $black;
    }
  }

  .detail {
    margin: 64px 0 150px 0;

    .news-image {
      text-align: center;
    }

    .news-text {
      margin-top: 43px;
      text-align: left;
      line-height: 24px;
      @media (max-width: $mobile) {
        margin-top: 16px;
      }
    }
  }

  h2 {
    font-weight: 900;
    font-size: 40px;
    line-height: 40px;
    color: $black;
    margin-bottom: 40px;

    @media (max-width: $mobile) {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 10px;
    }
  }

  .last {
    margin-bottom: 150px;

    .new-card {
      margin-bottom: 8px;
    }
  }

  @media (max-width: $mobile) {
    .detail {
      margin-top: 24px;
      margin-right: -15px;
      margin-left: -15px;
    }
  }
</style>
